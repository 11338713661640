import { cssPartial } from '@microsoft/fast-element';
import { bodyFont, typeRampBaseFontSize, typeRampBaseFontVariations, typeRampBaseLineHeight, typeRampMinus1FontSize, typeRampMinus1FontVariations, typeRampMinus1LineHeight, typeRampMinus2FontSize, typeRampMinus2FontVariations, typeRampMinus2LineHeight, typeRampPlus1FontSize, typeRampPlus1FontVariations, typeRampPlus1LineHeight, typeRampPlus2FontSize, typeRampPlus2FontVariations, typeRampPlus2LineHeight, typeRampPlus3FontSize, typeRampPlus3FontVariations, typeRampPlus3LineHeight, typeRampPlus4FontSize, typeRampPlus4FontVariations, typeRampPlus4LineHeight, typeRampPlus5FontSize, typeRampPlus5FontVariations, typeRampPlus5LineHeight, typeRampPlus6FontSize, typeRampPlus6FontVariations, typeRampPlus6LineHeight } from '../../design-tokens';
/** @public */
export const typeRampBase = cssPartial`
  font-family: ${bodyFont};
  font-size: ${typeRampBaseFontSize};
  line-height: ${typeRampBaseLineHeight};
  font-weight: initial;
  font-variation-settings: ${typeRampBaseFontVariations};
`;
/** @public */
export const typeRampMinus1 = cssPartial`
  font-family: ${bodyFont};
  font-size: ${typeRampMinus1FontSize};
  line-height: ${typeRampMinus1LineHeight};
  font-weight: initial;
  font-variation-settings: ${typeRampMinus1FontVariations};
`;
/** @public */
export const typeRampMinus2 = cssPartial`
  font-family: ${bodyFont};
  font-size: ${typeRampMinus2FontSize};
  line-height: ${typeRampMinus2LineHeight};
  font-weight: initial;
  font-variation-settings: ${typeRampMinus2FontVariations};
`;
/** @public */
export const typeRampPlus1 = cssPartial`
  font-family: ${bodyFont};
  font-size: ${typeRampPlus1FontSize};
  line-height: ${typeRampPlus1LineHeight};
  font-weight: initial;
  font-variation-settings: ${typeRampPlus1FontVariations};
`;
/** @public */
export const typeRampPlus2 = cssPartial`
  font-family: ${bodyFont};
  font-size: ${typeRampPlus2FontSize};
  line-height: ${typeRampPlus2LineHeight};
  font-weight: initial;
  font-variation-settings: ${typeRampPlus2FontVariations};
`;
/** @public */
export const typeRampPlus3 = cssPartial`
  font-family: ${bodyFont};
  font-size: ${typeRampPlus3FontSize};
  line-height: ${typeRampPlus3LineHeight};
  font-weight: initial;
  font-variation-settings: ${typeRampPlus3FontVariations};
`;
/** @public */
export const typeRampPlus4 = cssPartial`
  font-family: ${bodyFont};
  font-size: ${typeRampPlus4FontSize};
  line-height: ${typeRampPlus4LineHeight};
  font-weight: initial;
  font-variation-settings: ${typeRampPlus4FontVariations};
`;
/** @public */
export const typeRampPlus5 = cssPartial`
  font-family: ${bodyFont};
  font-size: ${typeRampPlus5FontSize};
  line-height: ${typeRampPlus5LineHeight};
  font-weight: initial;
  font-variation-settings: ${typeRampPlus5FontVariations};
`;
/** @public */
export const typeRampPlus6 = cssPartial`
  font-family: ${bodyFont};
  font-size: ${typeRampPlus6FontSize};
  line-height: ${typeRampPlus6LineHeight};
  font-weight: initial;
  font-variation-settings: ${typeRampPlus6FontVariations};
`;