import { __decorate } from "tslib";
import { attr } from "@microsoft/fast-element";
/**
 * Some states and properties are applicable to all host language elements regardless of whether a role is applied.
 * The following global states and properties are supported by all roles and by all base markup elements.
 * {@link https://www.w3.org/TR/wai-aria-1.1/#global_states}
 *
 * This is intended to be used as a mixin. Be sure you extend FASTElement.
 *
 * @public
 */
export class ARIAGlobalStatesAndProperties {}
__decorate([attr({
  attribute: "aria-atomic"
})], ARIAGlobalStatesAndProperties.prototype, "ariaAtomic", void 0);
__decorate([attr({
  attribute: "aria-busy"
})], ARIAGlobalStatesAndProperties.prototype, "ariaBusy", void 0);
__decorate([attr({
  attribute: "aria-controls"
})], ARIAGlobalStatesAndProperties.prototype, "ariaControls", void 0);
__decorate([attr({
  attribute: "aria-current"
})], ARIAGlobalStatesAndProperties.prototype, "ariaCurrent", void 0);
__decorate([attr({
  attribute: "aria-describedby"
})], ARIAGlobalStatesAndProperties.prototype, "ariaDescribedby", void 0);
__decorate([attr({
  attribute: "aria-details"
})], ARIAGlobalStatesAndProperties.prototype, "ariaDetails", void 0);
__decorate([attr({
  attribute: "aria-disabled"
})], ARIAGlobalStatesAndProperties.prototype, "ariaDisabled", void 0);
__decorate([attr({
  attribute: "aria-errormessage"
})], ARIAGlobalStatesAndProperties.prototype, "ariaErrormessage", void 0);
__decorate([attr({
  attribute: "aria-flowto"
})], ARIAGlobalStatesAndProperties.prototype, "ariaFlowto", void 0);
__decorate([attr({
  attribute: "aria-haspopup"
})], ARIAGlobalStatesAndProperties.prototype, "ariaHaspopup", void 0);
__decorate([attr({
  attribute: "aria-hidden"
})], ARIAGlobalStatesAndProperties.prototype, "ariaHidden", void 0);
__decorate([attr({
  attribute: "aria-invalid"
})], ARIAGlobalStatesAndProperties.prototype, "ariaInvalid", void 0);
__decorate([attr({
  attribute: "aria-keyshortcuts"
})], ARIAGlobalStatesAndProperties.prototype, "ariaKeyshortcuts", void 0);
__decorate([attr({
  attribute: "aria-label"
})], ARIAGlobalStatesAndProperties.prototype, "ariaLabel", void 0);
__decorate([attr({
  attribute: "aria-labelledby"
})], ARIAGlobalStatesAndProperties.prototype, "ariaLabelledby", void 0);
__decorate([attr({
  attribute: "aria-live"
})], ARIAGlobalStatesAndProperties.prototype, "ariaLive", void 0);
__decorate([attr({
  attribute: "aria-owns"
})], ARIAGlobalStatesAndProperties.prototype, "ariaOwns", void 0);
__decorate([attr({
  attribute: "aria-relevant"
})], ARIAGlobalStatesAndProperties.prototype, "ariaRelevant", void 0);
__decorate([attr({
  attribute: "aria-roledescription"
})], ARIAGlobalStatesAndProperties.prototype, "ariaRoledescription", void 0);