import { __decorate } from "tslib";
import { attr } from "@microsoft/fast-element";
import { FoundationElement } from "../foundation-element/foundation-element.js";
/**
 * An Avatar Custom HTML Element
 *
 * @slot media - Used for media such as an image
 * @slot - The default slot for avatar text, commonly a name or initials
 * @slot badge - Used to provide a badge, such as a status badge
 * @csspart backplate - The wrapping container for the avatar
 * @csspart link - The avatar link
 * @csspart media - The media slot
 * @csspart content - The default slot
 *
 * @public
 */
export class Avatar extends FoundationElement {
  /**
   * Internal
   */
  connectedCallback() {
    super.connectedCallback();
    if (!this.shape) {
      this.shape = "circle";
    }
  }
}
__decorate([attr], Avatar.prototype, "fill", void 0);
__decorate([attr], Avatar.prototype, "color", void 0);
__decorate([attr], Avatar.prototype, "link", void 0);
__decorate([attr], Avatar.prototype, "shape", void 0);