/**
 * Enumerates the data grid auto generated header options
 * default option generates a non-sticky header row
 *
 * @public
 */
export const GenerateHeaderOptions = {
  none: "none",
  default: "default",
  sticky: "sticky"
};
/**
 * Enumerates possible data grid cell types.
 *
 * @public
 */
export const DataGridCellTypes = {
  default: "default",
  columnHeader: "columnheader",
  rowHeader: "rowheader"
};
/**
 * Enumerates possible data grid row types
 *
 * @public
 */
export const DataGridRowTypes = {
  default: "default",
  header: "header",
  stickyHeader: "sticky-header"
};