import { __decorate } from "tslib";
import { observable } from "@microsoft/fast-element";
import { BreadcrumbItem } from "../breadcrumb-item/breadcrumb-item.js";
import { FoundationElement } from "../foundation-element/foundation-element.js";
/**
 * A Breadcrumb Custom HTML Element.
 * @slot - The default slot for the breadcrumb items
 * @csspart list - The element wrapping the slotted items
 *
 * @public
 */
export class Breadcrumb extends FoundationElement {
  slottedBreadcrumbItemsChanged() {
    if (this.$fastController.isConnected) {
      if (this.slottedBreadcrumbItems === undefined || this.slottedBreadcrumbItems.length === 0) {
        return;
      }
      const lastNode = this.slottedBreadcrumbItems[this.slottedBreadcrumbItems.length - 1];
      this.slottedBreadcrumbItems.forEach(item => {
        const itemIsLastNode = item === lastNode;
        this.setItemSeparator(item, itemIsLastNode);
        this.setAriaCurrent(item, itemIsLastNode);
      });
    }
  }
  setItemSeparator(item, isLastNode) {
    if (item instanceof BreadcrumbItem) {
      item.separator = !isLastNode;
    }
  }
  /**
   * Finds href on childnodes in the light DOM or shadow DOM.
   * We look in the shadow DOM because we insert an anchor when breadcrumb-item has an href.
   */
  findChildWithHref(node) {
    var _a, _b;
    if (node.childElementCount > 0) {
      return node.querySelector("a[href]");
    } else if ((_a = node.shadowRoot) === null || _a === void 0 ? void 0 : _a.childElementCount) {
      return (_b = node.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector("a[href]");
    } else return null;
  }
  /**
   *  Sets ARIA Current for the current node
   * If child node with an anchor tag and with href is found then set aria-current to correct value for the child node,
   * otherwise apply aria-current to the host element, with an href
   */
  setAriaCurrent(item, isLastNode) {
    const childNodeWithHref = this.findChildWithHref(item);
    if (childNodeWithHref === null && item.hasAttribute("href") && item instanceof BreadcrumbItem) {
      isLastNode ? item.setAttribute("aria-current", "page") : item.removeAttribute("aria-current");
    } else if (childNodeWithHref !== null) {
      isLastNode ? childNodeWithHref.setAttribute("aria-current", "page") : childNodeWithHref.removeAttribute("aria-current");
    }
  }
}
__decorate([observable], Breadcrumb.prototype, "slottedBreadcrumbItems", void 0);