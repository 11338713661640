import { ListboxElement } from "../listbox/listbox.element.js";
import { FormAssociated } from "../form-associated/form-associated.js";
class _Select extends ListboxElement {}
/**
 * A form-associated base class for the {@link @microsoft/fast-foundation#(Select:class)} component.
 *
 * @internal
 */
export class FormAssociatedSelect extends FormAssociated(_Select) {
  constructor() {
    super(...arguments);
    this.proxy = document.createElement("select");
  }
}