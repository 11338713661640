/**
 * This set of exported strings reference https://developer.mozilla.org/en-US/docs/Web/Events
 * and should include all non-deprecated and non-experimental Standard events
 */
export const eventAbort = "abort";
export const eventAfterPrint = "afterprint";
export const eventAnimationCancel = "animationcancel";
export const eventAnimationEnd = "animationend";
export const eventAnimationIteration = "animationiteration";
export const eventAnimationStart = "animationstart";
export const eventAppInstalled = "appinstalled";
export const eventBeforePrint = "beforeprint";
export const eventBeforeUnload = "beforeunload";
export const eventBeginEvent = "beginEvent";
export const eventBlocked = "blocked";
export const eventBlur = "blur";
export const eventCanPlay = "canplay";
export const eventCanPlayThrough = "canplaythrough";
export const eventChange = "change";
export const eventChargingChange = "chargingchange";
export const eventChargingTimeChange = "chargingtimechange";
export const eventClick = "click";
export const eventClose = "close";
export const eventComplete = "complete";
export const eventCompositionEnd = "compositionend";
export const eventCompositionStart = "compositionstart";
export const eventCompositionUpdate = "compositionupdate";
export const eventContextMenu = "contextmenu";
export const eventCopy = "copy";
export const eventCut = "cut";
export const eventDblClick = "dblclick";
export const eventDeviceChange = "devicechange";
export const eventDeviceMotion = "devicemotion";
export const eventDeviceOrientation = "deviceorientation";
export const eventDischargingTimeChange = "dischargingtimechange";
export const eventDrag = "drag";
export const eventDragEnd = "dragend";
export const eventDragEnter = "dragenter";
export const eventDragLeave = "dragleave";
export const eventDragOver = "dragover";
export const eventDragStart = "dragstart";
export const eventDrop = "drop";
export const eventDurationChange = "durationchange";
export const eventEmptied = "emptied";
export const eventEnded = "ended";
export const eventEndEvent = "endevent";
export const eventError = "error";
export const eventFocus = "focus";
export const eventFocusIn = "focusin";
export const eventFocusOut = "focusout";
export const eventFullScreenChange = "fullscreenchange";
export const eventFullScreenError = "fullscreenerror";
export const eventGamePadConnected = "gamepadconnected";
export const eventGamePadDisconnected = "gamepaddisconnected";
export const eventGotPointerCapture = "gotpointercapture";
export const eventHashChange = "hashchange";
export const eventLostPointerCapture = "lostpointercapture";
export const eventInput = "input";
export const eventInvalid = "invalid";
export const eventKeyDown = "keydown";
export const eventKeyUp = "keyup";
export const eventLevelChange = "levelchange";
export const eventLoad = "load";
export const eventLoadedData = "loadeddata";
export const eventLoadedMetaData = "loadedmetadata";
export const eventLoadEnd = "loadend";
export const eventLoadStart = "loadstart";
export const eventMessage = "message";
export const eventMessageError = "messageerror";
export const eventMouseDown = "mousedown";
export const eventMouseEnter = "mouseenter";
export const eventMouseLeave = "mouseleave";
export const eventMouseMove = "mousemove";
export const eventMouseOut = "mouseout";
export const eventMouseOver = "mouseover";
export const eventMouseUp = "mouseup";
export const eventNotificationClick = "notificationclick";
export const eventOffline = "offline";
export const eventOnline = "online";
export const eventOpen = "open";
export const eventOrientationChange = "orientationchange";
export const eventPageHide = "pagehide";
export const eventPageShow = "pageshow";
export const eventPaste = "paste";
export const eventPause = "pause";
export const eventPointerCancel = "pointercancel";
export const eventPointerDown = "pointerdown";
export const eventPointerEnter = "pointerenter";
export const eventPointerLeave = "pointerleave";
export const eventPointerLockChange = "pointerlockchange";
export const eventPointerLockError = "pointerlockerror";
export const eventPointerMove = "pointermove";
export const eventPointerOut = "pointerout";
export const eventPointerOver = "pointerover";
export const eventPointerUp = "pointerup";
export const eventPlay = "play";
export const eventPlaying = "playing";
export const eventPopState = "popstate";
export const eventProgress = "progress";
export const eventPush = "push";
export const eventPushSubscriptionChange = "pushsubscriptionchange";
export const eventRateChange = "ratechange";
export const eventReadyStateChange = "readystatechange";
export const eventRepeatEvent = "repeatevent";
export const eventReset = "reset";
export const eventResize = "resize";
export const eventResourceTimingBufferFull = "resourcetimingbufferfull";
export const eventScroll = "scroll";
export const eventSeeked = "seeked";
export const eventSeeking = "seeking";
export const eventSelect = "select";
export const eventShow = "show";
export const eventSlotChange = "slotchange";
export const eventStalled = "stalled";
export const eventStart = "start";
export const eventStorage = "storage";
export const eventSubmit = "submit";
export const eventSuccess = "success";
export const eventSuspend = "suspend";
export const eventSVGAbort = "SVGAbort";
export const eventSVGError = "SVGError";
export const eventSVGLoad = "SVGLoad";
export const eventSVGResize = "SVGResize";
export const eventSVGScroll = "SVGScroll";
export const eventSVGUnload = "SVGUnload";
export const eventSVGZoom = "SVGZoom";
export const eventTimeOut = "timeout";
export const eventTimeUpdate = "timeupdate";
export const eventTouchCancel = "touchcancel";
export const eventTouchEnd = "touchend";
export const eventTouchMove = "touchmove";
export const eventTouchStart = "touchstart";
export const eventTransitionEnd = "transitionend";
export const eventUnload = "unload";
export const eventUpgradeNeeded = "upgradeneeded";
export const eventUserProximity = "userproximity";
export const eventVersionChange = "versionchange";
export const eventVisibilityChange = "visibilitychange";
export const eventVolumeChange = "volumechange";
export const eventWaiting = "waiting";
export const eventWheel = "wheel";