import { css } from '@microsoft/fast-element';
import { SystemColors } from '@microsoft/fast-web-utilities';
import { display, focusVisible, forcedColorsStylesheetBehavior } from '@microsoft/fast-foundation';
import { heightNumber } from '../../styles';
import { controlCornerRadius, density, designUnit, neutralForegroundRest, strokeWidth } from '../../design-tokens';
import { typeRampBase } from '../../styles/patterns/type-ramp';
import { focusTreatmentBase } from '../../styles/focus';
export const tabStyles = (context, definition) => css`
      ${display('inline-flex')} :host {
        box-sizing: border-box;
        ${typeRampBase}
        height: calc((${heightNumber} + (${designUnit} * 2)) * 1px);
        padding: 0 calc((6 + (${designUnit} * 2 * ${density})) * 1px);
        color: ${neutralForegroundRest};
        border-radius: calc(${controlCornerRadius} * 1px);
        border: calc(${strokeWidth} * 1px) solid transparent;
        align-items: center;
        justify-content: center;
        grid-row: 1 / 3;
        cursor: pointer;
      }

      :host([aria-selected='true']) {
        z-index: 2;
      }

      :host(:hover),
      :host(:active) {
        color: ${neutralForegroundRest};
      }

      :host(:${focusVisible}) {
        ${focusTreatmentBase}
      }

      :host(.vertical) {
        justify-content: start;
        grid-column: 1 / 3;
      }

      :host(.vertical[aria-selected='true']) {
        z-index: 2;
      }

      :host(.vertical:hover),
      :host(.vertical:active) {
        color: ${neutralForegroundRest};
      }

      :host(.vertical:hover[aria-selected='true']) {
      }
    `.withBehaviors(forcedColorsStylesheetBehavior(css`
          :host {
            forced-color-adjust: none;
            border-color: transparent;
            color: ${SystemColors.ButtonText};
            fill: currentcolor;
          }
          :host(:hover),
          :host(.vertical:hover),
          :host([aria-selected='true']:hover) {
            background: transparent;
            color: ${SystemColors.Highlight};
            fill: currentcolor;
          }
          :host([aria-selected='true']) {
            background: transparent;
            color: ${SystemColors.Highlight};
            fill: currentcolor;
          }
          :host(:${focusVisible}) {
            background: transparent;
            outline-color: ${SystemColors.ButtonText};
          }
        `));